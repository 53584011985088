export const initialState = {
    appConfig: null,
    targetPerson: null,
    isMasterPasswordConfirmed:
        sessionStorage.getItem('MasterPassword') === 'true',
}

const reducer = (state, { type, payload }) => {
    switch (type) {
        case 'SET_APP_CONFIG':
            return {
                ...state,
                appConfig: payload,
            }

        case 'SET_TARGET_PERSON':
            return {
                ...state,
                targetPerson: payload,
            }

        case 'SET_IS_MASTER_PASSWORD_CONFIRMED':
            sessionStorage.setItem('MasterPassword', payload)
            return {
                ...state,
                isMasterPasswordConfirmed: payload,
            }

        default:
            return state
    }
}

export default reducer
