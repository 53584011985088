import { Fragment } from 'react'
import { Box, styled } from '@material-ui/core'
import { useIsSmallScreen } from 'packages/core'
import { useTranslation } from 'react-i18next'
import { ItemDetails } from 'components'

const PaddedDiv = styled(Box)({
    padding: '0 31px',
})

export const AdditionalAttributesSection = ({ credential }: any) => {
    const { t } = useTranslation()

    const attributeOrientation = useIsSmallScreen() ? 'vertical' : 'horizontal'

    return (
        <Fragment>
            <ItemDetails.AttributesContainer bottomPadding="0">
                <PaddedDiv>
                    <ItemDetails.Attribute
                        label={t('Display_Name')}
                        value={credential.friendlyName}
                        orientation={attributeOrientation}
                    />
                </PaddedDiv>
            </ItemDetails.AttributesContainer>
        </Fragment>
    )
}
