import { Fragment, useEffect, useState } from 'react'
import {
    Box,
    styled,
    Typography,
} from '@material-ui/core'
import { Loader, Select, Attribute, AttributeLabel, AttributeValue } from 'packages/eid-ui'
import { useTranslation } from 'react-i18next'
import {
    useCart,
    useCheckComputerAccess,
    useTargetPerson,
    useLinkedPeople,
    useComputerRequestableDetailOptions,
    useAccessRequestPolicy
} from 'hooks'
import { Icon } from 'packages/eid-icons'
import {
    DifferentiationValueSelector as ValueSelector,
    ItemDetails,
} from 'components'
import { useIsSmallScreen } from 'packages/core'
import cartHelpers from 'containers/Cart/cartHelpers'
import { AddToCart } from './AddToCart'
import { ResourceTypesNamespace, useRegistry } from 'core'
import { OverviewSection } from './OverviewSection'
import { MoreInformationSection } from './MoreInformationSection'
import { SelectorSection } from './SelectorSection'
import { useTimeConstrainedControl } from './TimeConstrainedControl'


const PaddedDiv = styled(Box)({
    padding: '0 31px',
})


const ComputerDetails = ({ computer, toggleDrawer }) => {

    const { t } = useTranslation()

    const MEMBERSHIP_BASED_ACCESS = t('Computer_MembershipBasedAccess')
    const LOGIN_SESSION_ACCESS = t('Computer_LoginSessionAccess')
    const ONE_TIME_ACCESS = t('Computer_OneTimeAccess')
    const PRE_APPROVED_ACCESS = t('Computer_PreApprovedAccess')
    const PERSONAL_CREDENTIALS = t('Computer_PersonalCredentials')
    const SHARED_CREDENTIALS = t('Computer_SharedCredentials')

    const allRequestTypes = [{ id: 1, friendlyName: MEMBERSHIP_BASED_ACCESS }, { id: 2, friendlyName: LOGIN_SESSION_ACCESS }]
    const allAccessTypes = [{ id: 1, friendlyName: ONE_TIME_ACCESS }, { id: 2, friendlyName: PRE_APPROVED_ACCESS }]
    const allCredentialTypes = [{ id: 1, friendlyName: PERSONAL_CREDENTIALS }, { id: 2, friendlyName: SHARED_CREDENTIALS }]


    const isSmallScreen = useIsSmallScreen()

    const [targetPerson] = useTargetPerson()

    const { data: cart } = useCart()

    const [activeTab, setActiveTab] = useState('overview')
    const [requestType, setRequestType] = useState(null)
    const [requestTypes, setRequestTypes] = useState(allRequestTypes)
    const [accessTypes, setAccessTypes] = useState(allAccessTypes)
    const [credentialTypes, setCredentialTypes] = useState(allCredentialTypes)
    const [accessType, setAccessType] = useState(null)
    const [accessLevel, setAccessLevelValue] = useState(null)
    const [person, setPerson] = useState(null)
    const [credentialType, setCredentialType] = useState(null)
    const [credentials, setCredentials] = useState(null)

    const {
        data,
        isLoading,
    } = useComputerRequestableDetailOptions(computer.id, targetPerson.id)

    const {
        data: accessLevels,
        isLoading: isCheckingAccess,
    } = useCheckComputerAccess(computer.id, targetPerson.id)

    const requestPolicy = useAccessRequestPolicy(
        computer.requestPolicyId,
    )

    const credentialsOptions = data?.personalCredentials;

    const {
        data: linkedPeople,
        isLoading: isCheckingLinkedPeople,
    } = useLinkedPeople(targetPerson.id)

    const computerAdditionalProps = { linkedCoreIdentity: person?.id, isPreApproved: accessType === PRE_APPROVED_ACCESS, personalCredentialId: credentials?.id }


    const {
        timeConstrained,
        selectedStartDate,
        selectedEndDate,
        hasInvalidDates,
        timeConstrainedControl, } = useTimeConstrainedControl(
            requestPolicy,
            true
        )

    useEffect(() => {
        if (data && !data.isMembershipEnabled) {
            const newRequestTypes = requestTypes.filter(x => x.friendlyName !== MEMBERSHIP_BASED_ACCESS)
            setRequestTypes(newRequestTypes)
        }
        if (data && !data.isPsmEnabled) {
            const newRequestTypes = requestTypes.filter(x => x.friendlyName !== LOGIN_SESSION_ACCESS)
            setRequestTypes(newRequestTypes)
        }

        if (data && !data.isPreApproved) {
            const newAccessTypes = accessTypes.filter(x => x.friendlyName !== PRE_APPROVED_ACCESS)
            setAccessTypes(newAccessTypes)
        }

        if (data && data.personalCredentials.length === 0) {
            const newCredentialTypes = credentialTypes.filter(x => x.friendlyName !== PERSONAL_CREDENTIALS)
            setCredentialTypes(newCredentialTypes)
        }
    }, [data])


    useEffect(() => {
        if (requestType?.friendlyName === LOGIN_SESSION_ACCESS) {
            setAccessLevelValue(null)
            setPerson(null)
        }
        if (requestType?.friendlyName === MEMBERSHIP_BASED_ACCESS) {
            setCredentialType(null)
            setCredentials(null)
        }
    }, [requestType])


    const registry = useRegistry()

    const computerType = registry.get(ResourceTypesNamespace, 'Computers')

    const getPreValidationMessage = () =>
        !Boolean(requestType) ? t('Common_SelectRequestType') : !Boolean(accessType) ? t('Common_SelectAccessType') : (requestType?.friendlyName === MEMBERSHIP_BASED_ACCESS && !Boolean(person)) ? t('Common_SelectPerson') : (requestType?.friendlyName === MEMBERSHIP_BASED_ACCESS && !Boolean(accessLevel)) ? t('Common_SelectAccessLevel') : (requestType?.friendlyName === LOGIN_SESSION_ACCESS && !Boolean(credentialType)) ? t('Common_SelectCredentialType') : (credentialType?.friendlyName === PERSONAL_CREDENTIALS && !Boolean(credentials)) ? t('Common_SelectCredentials') : (accessType?.friendlyName === ONE_TIME_ACCESS && hasInvalidDates) ? t('Common_InvalidDates') : ''

    const preAdd = () => {
        debugger
        const itemToAdd = cartHelpers.computerToCartItem({
            targetPerson,
            assignmentType: 'Add',
            computer,
            assignmentStatus: accessLevel,
            computerAdditionalProps,
            timeConstrained: accessType?.friendlyName === ONE_TIME_ACCESS,
            startDateUtc: accessType?.friendlyName === ONE_TIME_ACCESS ? selectedStartDate : null,
            endDateUtc: accessType?.friendlyName === ONE_TIME_ACCESS ? selectedEndDate : null,
        })
        if (!itemToAdd) return undefined

        return itemToAdd
    }

    const handlePersonChange = (person) => {
        setPerson(person)
    }

    const handleCredentialsChange = (credential) => {
        setCredentials(credential)
    }

    const requestTypeSelectorSection = (
        <SelectorSection
            sectionLabel={t('Common_SelectRequestType')}
            iconName="RequestType"
        >
            <ValueSelector
                onChange={(val) => setRequestType(val)}
                data={requestTypes}
                value={requestType?.id}
                getOptionLabel={(o) => o.friendlyName}
                checkSelectedOption={(o, selectedValueId) =>
                    o.id === selectedValueId
                }
            />
        </SelectorSection>
    )

    const accessTypeSelectorSection = (
        <SelectorSection
            sectionLabel={t('Common_SelectAccessType')}
            iconName="MonitorClock"
        >
            <ValueSelector
                onChange={(val) => setAccessType(val)}
                data={accessTypes}
                value={accessType?.id}
                getOptionLabel={(o) => o.friendlyName}
                checkSelectedOption={(o, selectedValueId) =>
                    o.id === selectedValueId
                }
            />
        </SelectorSection>
    )

    const accessLevelSelectorSection = (
        <SelectorSection
            sectionLabel={t('Common_SelectAccessLevel')}
            iconName="AccessLevel"
        >
            <ValueSelector
                loading={isCheckingAccess}
                onChange={(val) => setAccessLevelValue(val)}
                data={accessLevels && accessLevels.filter((o) => !o.isAssigned)}
                value={accessLevel?.resourceAssignment.resourceAssignmentId}
                getOptionLabel={(o) => o.resourceAssignment.friendlyName}
                checkSelectedOption={(o, selectedValueId) =>
                    o.resourceAssignment.resourceAssignmentId ===
                    selectedValueId
                }
                readOnlyOptions={
                    accessLevels && accessLevels.filter((o) => o.isAssigned)
                }
            />
        </SelectorSection>
    )

    const personSelectorSection = (
        <SelectorSection sectionLabel={t('Common_SelectPerson')}>
            {linkedPeople && (
                <Select
                    placeholder={t('Common_SelectPerson')}
                    options={linkedPeople}
                    loading={isCheckingLinkedPeople}
                    onChange={handlePersonChange}
                    value={person}
                    getOptionLabel={(option) => option.friendlyName}
                />
            )}
        </SelectorSection>
    )

    const durationSelectorSection = (
        <SelectorSection sectionLabel={t('Common_SelectDuration')}>
            <Box>
                <Attribute orientation="horizontal">
                    <AttributeLabel>{t('Common_DefaultAccessDuration')}</AttributeLabel>
                    <AttributeValue>
                        {requestPolicy && requestPolicy.defaultValueInMinutes}
                    </AttributeValue>
                </Attribute>

                <Attribute orientation="horizontal">
                    <AttributeLabel>
                        {t('Common_MaxAccessDuration')}
                    </AttributeLabel>
                    <AttributeValue>{requestPolicy && requestPolicy.maximumValueInMinutes}</AttributeValue>
                </Attribute>
                <Box>
                    {timeConstrainedControl}
                </Box>

            </Box>

        </SelectorSection>
    )

    const credentialsTypeSelectorSection = (
        <SelectorSection
            sectionLabel={t('Common_SelectCredentialType')}
            iconName="MonitorSettings"
        >
            <ValueSelector
                onChange={(val) => setCredentialType(val)}
                data={credentialTypes}
                value={credentialType?.id}
                getOptionLabel={(o) => o.friendlyName}
                checkSelectedOption={(o, selectedValueId) =>
                    o.id === selectedValueId
                }
            />
        </SelectorSection>
    )

    const credentialsSelectorSection = (
        <SelectorSection sectionLabel={t('Common_SelectCredentials')}>
            {data && (
                <Select
                    placeholder={t('Common_SelectCredentials')}
                    options={credentialsOptions}
                    loading={isLoading}
                    onChange={handleCredentialsChange}
                    value={credentials}
                    getOptionLabel={(option) => option.friendlyName}
                />
            )}
        </SelectorSection>
    )

    if (!cart) return <Loader />
    return (
        <Fragment>
            <ItemDetails.Tabs
                variant="standard"
                value={activeTab}
                onChange={(_, value) => setActiveTab(value)}
            >
                <ItemDetails.Tabs.Tab
                    value="overview"
                    label={t('Common_Overview')}
                />
                <ItemDetails.Tabs.Tab
                    value="additionalInformation"
                    label={t('Common_MoreInformation')}
                />
            </ItemDetails.Tabs>
            {activeTab === 'overview' && (
                <OverviewSection
                    computerRequestableDetails={
                        data
                    }
                />
            )}
            {activeTab === 'additionalInformation' && (
                <MoreInformationSection computer={computer} />
            )}
            {!isSmallScreen && (
                <Fragment>
                    {requestTypeSelectorSection}
                    {accessTypeSelectorSection}
                    {accessType?.friendlyName === ONE_TIME_ACCESS &&
                        durationSelectorSection}
                    {requestType?.friendlyName === MEMBERSHIP_BASED_ACCESS &&
                        personSelectorSection}
                    {requestType?.friendlyName === LOGIN_SESSION_ACCESS &&
                        credentialsTypeSelectorSection}
                    {credentialType?.friendlyName === PERSONAL_CREDENTIALS &&
                        credentialsSelectorSection}
                    {requestType?.friendlyName === MEMBERSHIP_BASED_ACCESS && accessLevelSelectorSection}
                </Fragment>
            )}

            {/* Need to setup UI for small screen as per requirements */}
            {isSmallScreen && (
                <PaddedDiv>
                    <Box
                        paddingY="24px"
                        display="flex"
                        flexDirection="column"
                        position="relative"
                    >
                        <Box position="absolute" top="16px" right="0">
                            <Icon
                                name="AccessLevel"
                                height="100px"
                                width="100px"
                                color="#D2D2D9"
                            />
                        </Box>
                        <Box width="172px" paddingY="4px" zIndex={1}>
                            <Typography
                                style={{
                                    fontSize: '14px',
                                    lineHeight: 1.43,
                                    fontWeight: 600,
                                    textTransform: 'uppercase',
                                    wordBreak: 'break-word',
                                }}
                            >
                                {t('Common_SelectAccessLevel')}
                            </Typography>
                        </Box>
                        <Box paddingY="16px">
                            {' '}
                            <ValueSelector
                                loading={isCheckingAccess}
                                onChange={(val) => setAccessLevelValue(val)}
                                data={
                                    accessLevels &&
                                    accessLevels.filter((o) => !o.isAssigned)
                                }
                                value={
                                    accessLevel?.resourceAssignment
                                        .resourceAssignmentId
                                }
                                getOptionLabel={(o) =>
                                    o.resourceAssignment.friendlyName
                                }
                                checkSelectedOption={(o, selectedValueId) =>
                                    o.resourceAssignment
                                        .resourceAssignmentId ===
                                    selectedValueId
                                }
                                readOnlyOptions={
                                    accessLevels &&
                                    accessLevels.filter((o) => o.isAssigned)
                                }
                            />
                        </Box>
                    </Box>
                </PaddedDiv>
            )}

            <AddToCart
                resourceType={computerType}
                resource={computer}
                secondary={accessLevel}
                preValidate={getPreValidationMessage}
                preAdd={preAdd}
                postAdd={toggleDrawer}
            />
        </Fragment>
    )
}

export default ComputerDetails
