import { requireAuth, useAuthState } from 'packages/core'
import { Loader } from 'packages/eid-ui'
import useConfiguration from 'useConfiguration'
import GuidedShopOrApp from './GuidedShopOrApp'
import GuidedShop from 'containers/GuidedShop'
import ManageAccess from 'containers/ManageAccess'
import ListingPage from 'containers/ListingPage'
import { Redirect, Route, Switch } from 'react-router-dom'
import { ResourceTypeContextProvider } from 'resourceTypeContext'
import withRequiredData from './withRequiredData'
import { ApplicationsListing } from 'containers/ApplicationsListing'
import { ApplicationBasedListing } from 'containers/ApplicationsListing/ApplicationBasedListing'
import WorkFlows from 'containers/Workflows'
import { Fragment, useEffect } from 'react'
import config from 'config'
import { setIsWorkflowRenderable } from 'packages/core/auth/actions'

const App = () => {
    const { manageAccess, resourceTypes } = useConfiguration()
    const [{ isWorkflowIRenderable }, authDispatch]: any = useAuthState()

    useEffect(() => {
        if (!isWorkflowIRenderable) {
            setTimeout(() => {
                authDispatch(setIsWorkflowRenderable(true))
            }, 30000)
        }
    }, [])

    return (
        <Fragment>
            {!isWorkflowIRenderable && (
                <div style={{ display: 'none' }}>
                    <iframe
                        title={`EmpowerID`}
                        src={`${config.BASE_EID_URL}/ui`}
                    />
                </div>
            )}

            <Switch>
                <Route exact path="/" component={GuidedShopOrApp} />
                <Route exact path="/guidedShop" component={GuidedShop} />
                <Route
                    exact
                    path="/workflows/:resourceType"
                    render={(props) => (
                        <ResourceTypeContextProvider>
                            <WorkFlows {...props} />
                        </ResourceTypeContextProvider>
                    )}
                />
                <Route
                    path="/applications"
                    render={(props) => (
                        <ResourceTypeContextProvider>
                            <ApplicationsListing {...props} />
                        </ResourceTypeContextProvider>
                    )}
                />

                <Route
                    exact
                    path={'/applications/:resourceType'}
                    render={(props) => (
                        <ResourceTypeContextProvider>
                            <ApplicationBasedListing />
                        </ResourceTypeContextProvider>
                    )}
                />

                {manageAccess &&
                    resourceTypes.map((r) => (
                        <Route
                            key={`${manageAccess.name}_${r.name}`}
                            exact
                            path={`${manageAccess.route}${r.route}`}
                            render={(props) => (
                                <ResourceTypeContextProvider>
                                    <ManageAccess
                                        resourceType={r.name}
                                        {...props}
                                    />
                                </ResourceTypeContextProvider>
                            )}
                        />
                    ))}
                {resourceTypes.map((r) => (
                    <Route
                        key={r.name}
                        exact
                        path={r.route}
                        render={(props) => (
                            <ResourceTypeContextProvider>
                                <ListingPage resourceType={r.name} {...props} />
                            </ResourceTypeContextProvider>
                        )}
                    />
                ))}
                <Redirect from="" to="/notfound" />
            </Switch>
        </Fragment>
    )
}

//@ts-ignore
export default requireAuth(withRequiredData(App), <Loader />)
