export const initialState = {
    tags: null,
    searchTerm: '',
    sorting: {
        sortOrder: null,
        sortBy: null,
    },
    manageAccess: {
        showTimeConstrained: false,
        showDirectAssignedOnly: true,
        accountStore: null,
        accountStoreUsageType: null,
        showExpiringAccess: false,
    },
    showOnlyAzureApplications: false,
}

const reducer = (state, { type, payload }) => {
    switch (type) {
        case 'SET_PROP':
            return {
                ...state,
                [payload.key]: payload.value,
            }

        case 'SET_MULTIPLE_PROPS':
            return {
                ...state,
                ...payload,
            }

        case 'SET_TAGS':
            return {
                ...state,
                tags: payload,
            }
        case 'SET_SEARCH_TERM':
            return {
                ...state,
                searchTerm: payload,
            }

        case 'SET_MANAGE_ACCESS_FILTERS':
            return {
                ...state,
                manageAccess: {
                    ...state.manageAccess,
                    ...payload,
                },
            }

        default:
            return state
    }
}

export default reducer
