import React, { useState, Fragment } from 'react'
import { useHistory } from 'react-router-dom'
import { useQuery, useIsSmallScreen } from 'packages/core'
import { useCredentialActions } from 'hooks/credentialsHooks'
import { Icon } from 'packages/eid-icons'
import { Skeleton } from '@material-ui/lab'
import {
    makeStyles,
    createStyles,
    Popover,
    MenuItem,
    Button,
} from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import { Modal } from 'components/Modal'
import { PasswordModal } from 'components/PasswordModal'
import { useAppState } from 'appContext'

const useBlueStyles = makeStyles(
    createStyles({
        root: {
            height: '31px',
            minWidth: '40px',
            padding: '0px',
            margin: '0px',
            borderRadius: '5px',

            // backgroundColor: '#efeff1',
            border: 'solid 1px #efeff1',

            '.MuiButton-startIcon ': {
                marginLeft: '0px',
                marginRight: '0px',
                backgroundColor: 'red',
            },

            '&:hover': {
                backgroundColor: '#efeff1',
            },
        },
    }),
)
export const CredentialActionsButton = ({ item }) => {
    const query = useQuery()
    const history = useHistory()
    const { t } = useTranslation()
    const buttonClasses = useBlueStyles()
    let selectedAction = query.get('action')
    const [{ isMasterPasswordConfirmed }, dispatch] = useAppState()
    const [open, setOpen] = useState('')

    const isSmallScreen = useIsSmallScreen()

    const [anchorEl, setAnchorEl] = useState(null)
    const data = [
        {
            id: 1234,
            friendlyName: 'Show Credentials',
            route: '?action=showCredentials',
        },
        {
            id: 5678,
            friendlyName: 'dynamic 1',
            route: '?action=pakistan&email=faizankhan247@live.com',
        },
    ]
    // const { data, isLoading } = useCredentialActions(item.id, Boolean(anchorEl))

    const openMenu = (event) => {
        setAnchorEl(event.currentTarget)
    }

    const closeMenu = () => {
        setAnchorEl(null)
    }

    const onActionClicked = (route) => {
        history.push(`/manageAccess/credentials${route}`)
    }

    if (!isMasterPasswordConfirmed) {
        return (
            <PasswordModal open={open} setOpen={setOpen}>
                <Button
                    onClick={() =>
                        setOpen(
                            isMasterPasswordConfirmed
                                ? 'addToCart'
                                : 'enterPassword',
                        )
                    }
                    startIcon={<Icon name="Settings" />}
                >
                    locked
                </Button>
            </PasswordModal>
        )
    }
    return (
        <Fragment>
            {selectedAction === 'showCredentials' && (
                <Modal
                    open={true}
                    onClose={() => history.push(`/manageAccess/credentials`)}
                    title={t('Show Credentials')}
                >
                    <h1>Hello World</h1>
                </Modal>
            )}

            <Button
                onClick={openMenu}
                classes={buttonClasses}
                startIcon={<Icon name="Settings" />}
            />
            <Popover
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={closeMenu}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
            >
                {/* {isLoading && <Skeleton height={40} width={50} />} */}
                {data &&
                    data.map((actionItem) => (
                        <MenuItem
                            key={actionItem.id}
                            onClick={() => {
                                closeMenu()
                                onActionClicked(actionItem.route)
                            }}
                            // className={classes.menuItem}
                        >
                            {actionItem.friendlyName}
                        </MenuItem>
                    ))}
            </Popover>
        </Fragment>
    )
}
