import {
    isNilOrEmpty,
    useGetControlsAccess,
    useGetPagesAccess,
} from 'packages/core'
import appConfiguration from 'appConfiguration'
import { find } from 'ramda'

export default () => {
    const pages = useGetPagesAccess()
    const controls = useGetControlsAccess()
    const configuration = appConfiguration

    const manageAccess = {
        name: 'ManageAccess',
        ...configuration.manageAccess,
    }

    const resourceTypesWithAccess = configuration.resourceTypes
        .filter(
            (r) =>
                isNilOrEmpty(r.requireAccess?.page) ||
                pages.findIndex((x) => x.name === r.requireAccess.page) > -1,
        )
        .sort((a, b) => a.order - b.order)

    const getResourceType = (name) =>
        find(
            (x) => x.name.toLowerCase() === name.toLowerCase(),
            resourceTypesWithAccess,
        )

    const getResourceTypeAttributes = (
        resourceType,
        forManageAccess = false,
    ) => {
        const result = getResourceType(resourceType)
            .attributes.filter(
                (a) =>
                    a.forManageAccess === undefined ||
                    a.forManageAccess === forManageAccess,
            )
            .filter((a) => {
                if (isNilOrEmpty(a.requireAccess)) {
                    return true
                }
                if (
                    controls.findIndex(
                        (x) => x.name === a.requireAccess.control,
                    ) > -1
                ) {
                    return true
                }
                return false
            })

        return result
    }

    return {
        manageAccess,
        resourceTypes: resourceTypesWithAccess,
        getResourceType,
        getResourceTypeAttributes,
    }
}
