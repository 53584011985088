import { useQuery, useMutation } from 'react-query'
import { useAxios } from 'packages/core'

export const CURRENT_PERSON_KEY = 'CURRENT_PERSON'
export const PERSON_KEY = 'PERSON'

export const useCurrentPerson = () => {
    const callApi = useAxios()
    return useQuery(
        CURRENT_PERSON_KEY,
        () =>
            callApi({
                method: 'GET',
                url: '/api/people',
            }).then((data) => data.data),
        {
            staleTime: Infinity,
        },
    )
}
export const usePerson = (personId, enabled) => {
    const callApi = useAxios()
    return useQuery(
        [PERSON_KEY, personId, enabled],
        () =>
            callApi({
                method: 'GET',
                url: `/api/people?personId=${personId}`,
            }).then((data) => data.data),
        {
            staleTime: Infinity,
            enabled: enabled,
        },
    )
}

export const useLinkedPeople = (id) => {
    const callApi = useAxios()
    return useQuery(
        [`${CURRENT_PERSON_KEY}_LINKEDPEOPLE`, id],
        () =>
            callApi({
                method: 'GET',
                url: `/api/people/linkedPeople/?targetpersonId=${id}`,
            }).then((data) => data.data),
        {
            staleTime: Infinity,
        },
    )
}

export const useUpdatePersonLocale = () => {
    const callApi = useAxios()
    return useMutation(
        (locale) =>
            callApi({
                method: 'PATCH',
                url: '/api/people/locale',
                data: locale,
            }),
        {
            onSuccess: () => {
                window.location.reload()
            },
        },
    )
}
