import { useState } from 'react'
import { Box, styled } from '@material-ui/core'
import { Loader } from 'packages/eid-ui'
import { useTranslation } from 'react-i18next'
import {
    useCart,
    useCredentialBusinessRequest,
    useCredentialOwners,
    useTargetPerson,
} from 'hooks'
import { ItemDetails, PeopleList } from 'components'
import { useIsSmallScreen } from 'packages/core'
import cartHelpers from 'containers/Cart/cartHelpers'
import { ResourceTypesNamespace, useRegistry } from 'core'
import { RenewRevoke } from 'components/RenewRevoke'
import { CommonAttributes } from '../CommonAttributes'
import { Skeleton } from '@material-ui/lab'

const PaddedDiv = styled(Box)({
    padding: '0 31px',
})

const overflowStyleProps = {
    maxHeight: '220px',
    overflow: 'auto',
}

const OverviewSection = ({ item }) => {
    const credential = item.resource
    const { t } = useTranslation()

    const [targetPerson] = useTargetPerson()

    const {
        data: businessRequestItem,
        isLoading,
    } = useCredentialBusinessRequest(
        targetPerson.id,
        item.assignment.resourceAssignment.resourceId,
        item.assignment.resourceAssignment.resourceTypeId,
        item.assignment.resourceAssignment.resourceAssignmentId,
    )

    const attributeOrientation = useIsSmallScreen() ? 'vertical' : 'horizontal'

    return (
        <ItemDetails.AttributesContainer>
            <PaddedDiv>
                <ItemDetails.Attribute
                    label={t('Common_CheckIn')}
                    value={'CheckIn'}
                    orientation={attributeOrientation}
                    valueProps={overflowStyleProps}
                />

                <ItemDetails.Attribute
                    label={t('Common_CheckedOut')}
                    value={'CheckedOut'}
                    orientation={attributeOrientation}
                    valueProps={overflowStyleProps}
                />

                <ItemDetails.Attribute
                    label={t('Common_SharedCredential')}
                    value={'SharedCredential'}
                    orientation={attributeOrientation}
                    valueProps={overflowStyleProps}
                />

                <ItemDetails.Attribute
                    label={t('Common_Computer')}
                    value={'Computer'}
                    orientation={attributeOrientation}
                    valueProps={overflowStyleProps}
                />

                <ItemDetails.Attribute
                    label={t('Common_Approver')}
                    value={'Approver'}
                    orientation={attributeOrientation}
                    valueProps={overflowStyleProps}
                />

                <ItemDetails.Attribute
                    label={t('Common_StartTime')}
                    value={'StartTime'}
                    orientation={attributeOrientation}
                    valueProps={overflowStyleProps}
                />

                <ItemDetails.Attribute
                    label={t('Common_EndTime')}
                    value={'EndTime'}
                    orientation={attributeOrientation}
                    valueProps={overflowStyleProps}
                />

                <ItemDetails.Attribute
                    label={t('Common_ActualCheckOutTime')}
                    value={'ActualCheckOutTime'}
                    orientation={attributeOrientation}
                    valueProps={overflowStyleProps}
                />

                {isLoading && <Skeleton height={32} />}

                <CommonAttributes
                    item={item}
                    businessRequestItem={businessRequestItem}
                />
            </PaddedDiv>
        </ItemDetails.AttributesContainer>
    )
}

const AdditionalInformationSection = ({ item }) => {
    const credential = item.resource
    const { t } = useTranslation()

    const attributeOrientation = useIsSmallScreen() ? 'vertical' : 'horizontal'

    const { data: owners, isLoading: ownersLoading } = useCredentialOwners(
        credential.id,
    )

    return (
        <>
            {ownersLoading ? (
                <ItemDetails.AttributesContainer>
                    <PaddedDiv>
                        <ItemDetails.AttributesLoader />
                    </PaddedDiv>
                </ItemDetails.AttributesContainer>
            ) : (
                <ItemDetails.AttributesContainer bottomPadding="0">
                    <PaddedDiv>
                        <ItemDetails.Attribute
                            label={t('Common_Owners')}
                            value={<PeopleList list={owners} />}
                            orientation={attributeOrientation}
                        />
                    </PaddedDiv>
                </ItemDetails.AttributesContainer>
            )}
        </>
    )
}

const CredentialDetails = ({ item, toggleDrawer }) => {
    const { t } = useTranslation()
    const [targetPerson] = useTargetPerson()

    const [activeTab, setActiveTab] = useState('overview')

    return (
        <>
            <ItemDetails.Tabs
                variant="standard"
                value={activeTab}
                onChange={(_, value) => setActiveTab(value)}
            >
                <ItemDetails.Tabs.Tab
                    value="overview"
                    label={t('Common_Overview')}
                />

                <ItemDetails.Tabs.Tab
                    value="additionalInformation"
                    label={t('Common_MoreInformation')}
                />
            </ItemDetails.Tabs>

            {activeTab === 'overview' && <OverviewSection item={item} />}

            {activeTab === 'additionalInformation' && (
                <AdditionalInformationSection item={item} />
            )}
        </>
    )
}

export default CredentialDetails
