import { useResourceTypeContext } from 'resourceTypeContext'
import { isNilOrEmpty } from 'packages/core'
import { useTranslation } from 'react-i18next'

const useAppliedFilterValues = (currentResourceType) => {
    const { t } = useTranslation()

    const [appState, dispatch] = useResourceTypeContext()

    const isGenericFilterApplied = (filter) =>
        !isNilOrEmpty(appState[filter.contextProp]) &&
        appState[filter.contextProp] !== false

    const getGenericFilterAppliedValueLabel = (filter) => {
        switch (filter.type) {
            case 'checkbox':
                return t(filter.trueValueIndicator)
            case 'tree':
                return appState[filter.contextProp][
                    filter.selectedValueIndicatorProp
                ]
            case 'autocomplete':
                return appState[filter.contextProp][
                    filter.optionConfig.labelProp
                ]
            case 'select':
                return appState[filter.contextProp][
                    filter.optionConfig.labelProp
                ]
            default:
                return appState[filter.contextProp]
        }
    }

    const getGenericFilterFalsyValue = (filter) => {
        switch (filter.type) {
            case 'checkbox':
                return false
            case 'text':
                return ''
            case 'checklist':
                return []
            case 'tree':
                return null
            case 'autocomplete':
                return null
            case 'select':
                return ''
            default:
                return null
        }
    }

    const getAppliedFiltersValues = () => {
        const targetSystemFilters = []
        const genericFilters = []
        const checklistFiltersValues = []
        const tagFiltersValues = []

        if (appState.accountStoreUsageType) {
            targetSystemFilters.push({
                name: 'targetSystem',
                label: appState.accountStoreUsageType.friendlyName,
                category: 'Generic',
                colorCode: {
                    primary: '#307fc1',
                    secondary: 'rgba(48, 127, 193, 0.09)',
                },
                onRemove: () => {
                    dispatch({
                        type: 'SET_MULTIPLE_PROPS',
                        payload: {
                            accountStoreUsageType: null,
                            accountStore: null,
                        },
                    })
                },
            })
        }

        if (appState.accountStore) {
            targetSystemFilters.push({
                name: 'targetSystem',
                label: appState.accountStore.friendlyName,
                category: 'Generic',
                colorCode: {
                    primary: '#307fc1',
                    secondary: 'rgba(48, 127, 193, 0.09)',
                },
                onRemove: () => {
                    dispatch({
                        type: 'SET_MULTIPLE_PROPS',
                        payload: {
                            accountStore: null,
                        },
                    })
                },
            })
        }

        for (let i = 0; i < currentResourceType.filters.length; i++) {
            const currentFilter = currentResourceType.filters[i]

            if (currentFilter.type === 'checklist') {
                if (isGenericFilterApplied(currentFilter)) {
                    appState[currentFilter.contextProp].forEach((i) => {
                        checklistFiltersValues.push({
                            ...currentFilter,
                            label: i[currentFilter.selectedValueIndicatorProp],
                            category: 'checklist',
                            onRemove: () => {
                                const newList = [
                                    ...appState[currentFilter.contextProp],
                                ]
                                const itemIndex = newList.findIndex(
                                    (f) => f.id === i.id,
                                )
                                newList.splice(itemIndex, 1)

                                dispatch({
                                    type: 'SET_PROP',
                                    payload: {
                                        key: currentFilter.contextProp,
                                        value: newList,
                                    },
                                })
                            },
                        })
                    })
                }
            } else if (
                currentFilter.type !== 'advancedSearch' &&
                currentFilter.name !== 'TargetSystem'
            ) {
                if (isGenericFilterApplied(currentFilter)) {
                    genericFilters.push({
                        ...currentFilter,
                        category: 'generic',
                    })
                }
            } else {
                if (!isNilOrEmpty(currentFilter.forms)) {
                    currentFilter.forms.filters
                        .filter((f) => isGenericFilterApplied(f))
                        .forEach((f) =>
                            genericFilters.push({
                                ...f,
                                category: 'forms',
                                colorCode: currentFilter.forms.colorCode,
                            }),
                        )
                }
                if (!isNilOrEmpty(currentFilter.tags)) {
                    if (
                        !isNilOrEmpty(appState[currentFilter.tags.contextProp])
                    ) {
                        appState[currentFilter.tags.contextProp].forEach(
                            (t) => {
                                tagFiltersValues.push({
                                    name: 'tags',
                                    label: t,
                                    category: 'tags',
                                    colorCode: currentFilter.tags.colorCode,
                                    onRemove: () => {
                                        const newTags = [
                                            ...appState[
                                                currentFilter.tags.contextProp
                                            ],
                                        ]
                                        const tagIndex = newTags.findIndex(
                                            (f) => f === t,
                                        )
                                        newTags.splice(tagIndex, 1)

                                        dispatch({
                                            type: 'SET_PROP',
                                            payload: {
                                                key:
                                                    currentFilter.tags
                                                        .contextProp,
                                                value: newTags,
                                            },
                                        })
                                    },
                                })
                            },
                        )
                    }
                }
            }
        }

        const genericFilterValues = genericFilters.map((f) => ({
            name: f.name,
            label: getGenericFilterAppliedValueLabel(f),
            category: f.category,
            colorCode: f.colorCode,
            onRemove: () =>
                dispatch({
                    type: 'SET_PROP',
                    payload: {
                        key: f.contextProp,
                        value: getGenericFilterFalsyValue(f),
                    },
                }),
        }))

        const appliedFilterValues = targetSystemFilters
            .concat(genericFilterValues)
            .concat(checklistFiltersValues)
            .concat(tagFiltersValues)

        return appliedFilterValues
    }

    const appliedFilterValues = getAppliedFiltersValues()

    return appliedFilterValues
}

export default useAppliedFilterValues
