import React, { Fragment, useEffect, useState } from 'react'
import { Box, Link, styled, Typography } from '@material-ui/core'
import { isNilOrEmpty, useIsSmallScreen } from 'packages/core'
import { useTranslation } from 'react-i18next'
import {
    ApplicationsList,
    DifferentiationValueSelector,
    ItemDetails,
    SuggestedRoles,
    TCodesList,
} from 'components'
import {
    useAccessRequestPolicy,
    useBusinessRoleApplications,
    useBusinessRoleDifferentiationValues,
    useBusinessRoleSuggestedApplicationRoles,
    useBusinessRoleSuggestedManagementRoles,
    useCheckBusinessRoleAccess,
    useTargetPerson,
} from 'hooks'
import { useResourceTypeContext } from 'resourceTypeContext'
import { DifferentiationValueIcon } from 'packages/eid-icons'
import { genericHashLink } from 'utils'
import useSubcomponents from 'useSubcomponents'
import cartHelpers from 'containers/Cart/cartHelpers'
import { MoreInformationSection } from './MoreInformationSection'
import { OverviewSection } from './OverviewSection'
import { ApplicationRolesGranted } from './ApplicationRolesGranted'
import { ManagementRolesGranted } from './ManagementRolesGranted'
import { LocalSensitiveFunctions } from './LocalSensitiveFunctions'
import { Approvers } from './Approvers'
import { AddToCart } from 'components/AddToCart'
import { ResourceTypesNamespace, useRegistry } from 'core'
import { AccessRequestPolicyDetails } from 'components/AccessRequestPolicyDetails'

const HashLink = genericHashLink(Link)

const PaddedDiv = styled(Box)({
    padding: '0 31px',
})

const PromptContainer = styled('div')({
    padding: '48px 31px 20px 31px',
    minHeight: '232px',
    display: 'flex',
})

const BusinessRoleDetails = ({ businessRole, toggleDrawer }) => {
    const { t } = useTranslation()
    const registry = useRegistry()
    const businessRoleType = registry.get(
        ResourceTypesNamespace,
        'BusinessRoles',
    )

    const isSmallScreen = useIsSmallScreen()

    const {
        hasAccessToTCodesGrid,
        hasAccessToLocalSensitiveFunctionsGrid,
        canSeeManagementRolesGranted,
        canSeeApplicationRolesGranted,
        canSeeApplications,
        canSeeManagementRolesSuggestedGrid,
    } = useSubcomponents()

    const [targetPerson] = useTargetPerson()
    const [
        { referencePerson, showPreApproved, showSuggested },
    ] = useResourceTypeContext()

    const {
        data: differentiationValues,
        isLoading: isLoadingDifferentiationValues,
    } = useBusinessRoleDifferentiationValues(
        businessRole.id,
        targetPerson.id,
        referencePerson?.id,
        showPreApproved,
        showSuggested,
    )

    const [activeTab, setActiveTab] = useState('overview')
    const [subActiveTab, setSubActiveTab] = useState('applicationRolesGranted')

    const [differentiationValue, setDifferentiationValue] = useState(null)

    const {
        data: assignmentStatus,
        isLoading: isCheckingAccess,
    } = useCheckBusinessRoleAccess(
        businessRole.id,
        differentiationValue?.id,
        targetPerson.id,
    )

    useEffect(() => {
        if (differentiationValues && differentiationValues.length === 1) {
            setDifferentiationValue(differentiationValues[0])
        }
    }, [differentiationValues, setDifferentiationValue])

    const preAdd = (baseObj) => {
        const itemToAdd = cartHelpers.businessRoleToCartItem({
            targetPerson,
            assignmentType: 'Add',
            businessRole,
            differentiationValue,
            assignmentStatus,
            ...baseObj,
        })
        if (!itemToAdd) return undefined

        return itemToAdd
    }

    const [suggestedRolesCount, setSuggestedRolesTotalCount] = useState(null)

    const policy = useAccessRequestPolicy(
        '',
        businessRole.id,
        differentiationValue?.id,
    )

    const scrollToSuggestedApplicationRolesLink = (
        <HashLink
            smooth
            component="button"
            underline="always"
            to={`/BusinessRoles?selected=${businessRole.id}#SuggestedApplicationRoles`}
            scroll={(el) => el.scrollIntoView()}
        >
            {t('BusinessRoles_SeeSuggestedApplicationRoles', {
                count: suggestedRolesCount,
            })}
        </HashLink>
    )

    const selectDifferentiationValueLabel = (
        <Typography
            style={{
                fontSize: '14px',
                lineHeight: 1.43,
                fontWeight: 600,
                textTransform: 'uppercase',
            }}
        >
            {t('BusinessRoles_SelectDifferentiationValue')}
        </Typography>
    )

    const differentiationValueSelector = (
        <DifferentiationValueSelector
            loading={isLoadingDifferentiationValues}
            onChange={(val) => setDifferentiationValue(val)}
            data={differentiationValues}
            value={differentiationValue?.id}
        />
    )

    const getPreValidationMessage = () =>
        !Boolean(differentiationValue)
            ? t('BusinessRoles_SelectDifferentiationValue')
            : ''

    return (
        <>
            <Box>
                <ItemDetails.Tabs
                    variant="standard"
                    value={activeTab}
                    onChange={(_, value) => setActiveTab(value)}
                >
                    <ItemDetails.Tabs.Tab
                        value="overview"
                        label={t('Common_Overview')}
                    />
                    <ItemDetails.Tabs.Tab
                        value="additionalInformation"
                        label={t('Common_MoreInformation')}
                    />
                </ItemDetails.Tabs>
            </Box>

            {activeTab === 'overview' && (
                <OverviewSection businessRole={businessRole} />
            )}

            {activeTab === 'additionalInformation' && (
                <MoreInformationSection businessRole={businessRole} />
            )}

            {!isSmallScreen && (
                <PromptContainer>
                    <Box
                        width="172px"
                        display="flex"
                        flexDirection="column"
                        zIndex={1}
                    >
                        {selectDifferentiationValueLabel}

                        <Box
                            marginTop="24px"
                            width="100%"
                            display="flex"
                            justifyContent="center"
                        >
                            <DifferentiationValueIcon />
                        </Box>
                    </Box>

                    <Box width="334px" paddingLeft="16px">
                        {differentiationValueSelector}
                    </Box>
                    <Box marginLeft={'16px'} width={'220px'}>
                        {policy && (
                            <AccessRequestPolicyDetails
                                policy={policy}
                                orientation={'vertical'}
                            />
                        )}
                    </Box>
                </PromptContainer>
            )}

            {isSmallScreen && (
                <PaddedDiv>
                    <Box
                        paddingY="24px"
                        display="flex"
                        flexDirection="column"
                        position="relative"
                    >
                        <Box position="absolute" top="16px" right="0">
                            <DifferentiationValueIcon
                                width="107px"
                                height="100px"
                            />
                        </Box>

                        <Box width="172px" paddingY="4px" zIndex={1}>
                            {selectDifferentiationValueLabel}
                        </Box>

                        <Box paddingY="4px">{differentiationValueSelector}</Box>

                        {policy && (
                            <Box marginTop={'4px'}>
                                <AccessRequestPolicyDetails
                                    policy={policy}
                                    orientation={'vertical'}
                                />
                            </Box>
                        )}
                    </Box>
                </PaddedDiv>
            )}

            <AddToCart
                resourceType={businessRoleType}
                resource={businessRole}
                secondary={differentiationValue}
                preValidate={getPreValidationMessage}
                preAdd={preAdd}
                postAdd={toggleDrawer}
            />

            {differentiationValue && suggestedRolesCount > 0 && (
                <Box paddingX="31px" paddingY="16px" textAlign="right">
                    {scrollToSuggestedApplicationRolesLink}
                </Box>
            )}

            {differentiationValue && (
                <Fragment>
                    <Box paddingRight="12px">
                        <ItemDetails.Tabs
                            variant={'scrollable'}
                            scrollButtons={'on'}
                            value={subActiveTab}
                            onChange={(_, value) => setSubActiveTab(value)}
                        >
                            {canSeeApplicationRolesGranted && (
                                <ItemDetails.Tabs.Tab
                                    value="applicationRolesGranted"
                                    label={t(
                                        'BusinessRoles_ApplicationRolesGranted',
                                    )}
                                />
                            )}

                            {canSeeManagementRolesGranted && (
                                <ItemDetails.Tabs.Tab
                                    value="managementRolesGranted"
                                    label={t('Common_ManagementRolesGranted')}
                                />
                            )}

                            {hasAccessToLocalSensitiveFunctionsGrid && (
                                <ItemDetails.Tabs.Tab
                                    value="localSensitiveFunctions"
                                    label={t('Common_LocalSensitiveFunctions')}
                                />
                            )}
                            <ItemDetails.Tabs.Tab
                                value="approvers"
                                label={t('Common_Approvers')}
                                disabled={isNilOrEmpty(differentiationValue)}
                            />
                            {hasAccessToTCodesGrid && (
                                <ItemDetails.Tabs.Tab
                                    value="tCodes"
                                    label={t('Common_TCodes')}
                                />
                            )}
                            {canSeeApplications && (
                                <ItemDetails.Tabs.Tab
                                    value="applications"
                                    label={t('Common_Applications')}
                                />
                            )}
                        </ItemDetails.Tabs>
                    </Box>

                    <Box overflow="auto">
                        {subActiveTab === 'applicationRolesGranted' && (
                            <ApplicationRolesGranted
                                businessRole={businessRole}
                                differentiationValue={differentiationValue}
                            />
                        )}

                        {subActiveTab === 'managementRolesGranted' && (
                            <ManagementRolesGranted
                                businessRole={businessRole}
                                differentiationValue={differentiationValue}
                            />
                        )}

                        {subActiveTab === 'localSensitiveFunctions' && (
                            <LocalSensitiveFunctions
                                businessRole={businessRole}
                                differentiationValue={differentiationValue}
                            />
                        )}
                        {subActiveTab == 'approvers' && (
                            <Approvers
                                businessRole={businessRole}
                                differentiationValue={differentiationValue}
                            />
                        )}
                        {subActiveTab === 'tCodes' && (
                            <TCodesList
                                resourceId={businessRole.id}
                                differentiationValueId={differentiationValue.id}
                            />
                        )}

                        {subActiveTab == 'applications' && (
                            <ApplicationsList
                                resource={businessRole}
                                useDataHook={useBusinessRoleApplications}
                                locationId={differentiationValue?.id}
                            />
                        )}
                    </Box>
                    <Box
                        overflow="auto"
                        width="100%"
                        id="SuggestedApplicationRoles"
                    >
                        <SuggestedRoles
                            title={t('BusinessRoles_SuggestedApplicationRoles')}
                            toggleDrawer={toggleDrawer}
                            resource={businessRole}
                            differentiationValue={differentiationValue}
                            targetPerson={targetPerson}
                            useDataHook={
                                useBusinessRoleSuggestedApplicationRoles
                            }
                            onDataLoaded={(data) =>
                                setSuggestedRolesTotalCount(data.totalCount)
                            }
                        />
                    </Box>
                    {canSeeManagementRolesSuggestedGrid && (
                        <Box
                            overflow="auto"
                            width="100%"
                            id="SuggestedManagementRoles"
                        >
                            <SuggestedRoles
                                title={t('Common_SuggestedManagementRoles')}
                                toggleDrawer={toggleDrawer}
                                resource={businessRole}
                                differentiationValue={differentiationValue}
                                targetPerson={targetPerson}
                                useDataHook={
                                    useBusinessRoleSuggestedManagementRoles
                                }
                            />
                        </Box>
                    )}
                </Fragment>
            )}
        </>
    )
}

export default BusinessRoleDetails
