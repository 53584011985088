import { InputBase, Box } from '@material-ui/core'

const DummyPasswordBox = ({ attribute, data }) => {
    return (
        <Box display="flex" alignItems="center" justifyContent="center">
            <InputBase
                value={'*'.repeat(
                    attribute.component.length ? attribute.component.length : 5,
                )}
                type={'password'}
            />
        </Box>
    )
}

export default DummyPasswordBox
