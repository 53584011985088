export const getResourceItemFromList = (id, list, currentResourceTypeName) => {
    let item = null
    switch (currentResourceTypeName) {
        case 'BusinessRoles':
            item = list?.find(
                (i) =>
                    i.assignment.resourceAssignment.resourceAssignmentId === id,
            )
            break
        case 'ApplicationRoles':
        case 'AzureLicenses':
        case 'AzureAdminRoles':
        case 'ManagementRoles':
        case 'SharedFolders':
        case 'Mailboxes':

        default:
            item = list.find((i) => i.resource.id === id)
            break
    }
    return item
}

export const getUniqueId = (item) => {
    switch (item.resource.resourceTypeName) {
        case 'BusinessRole':
            return item.assignment.resourceAssignment.resourceAssignmentId
        case 'ApplicationRole':
        case 'AzureLicense':
        case 'AzureAdminRole':
        case 'ManagementRole':
        case 'SharedFolders':
        default:
            return item.resource.id
    }
}
