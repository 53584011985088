import { default as EndDateManageAccess } from './EndDateManageAccess'
import { default as ConnectSwitch } from './ConnectSwitch'
import { default as DummyPasswordBox } from './DummyPasswordBox'

const DynamicComponents = {
    EndDateManageAccess,
    ConnectSwitch,
    DummyPasswordBox,
}

export default DynamicComponents
