import { useQuery } from 'react-query'
import { useAxios } from 'packages/core'
export const CREDENTIAL_KEY_PREFIX = 'CREDENTIALS'

export const useCredentialActions = (id) => {
    const callApi = useAxios()
    return useQuery(
        [CREDENTIAL_KEY_PREFIX, 'ACTIONS', id],
        () =>
            callApi({
                method: 'GET',
                url: `/api/Credentials/${id}`,
            }).then((data) => data.data),
        {
            enabled: Boolean(id),
        },
    )
}

export const useCredential = (id) => {
    const callApi = useAxios()
    return useQuery(
        [CREDENTIAL_KEY_PREFIX, id],
        () =>
            callApi({
                method: 'GET',
                url: `/api/Credentials/${id}`,
            }).then((data) => data.data),
        {
            enabled: Boolean(id),
        },
    )
}

export const useCheckCredentialAccess = (id, targetPersonId) => {
    const callApi = useAxios()
    return useQuery(
        [`${CREDENTIAL_KEY_PREFIX}_ACCESS`, id, targetPersonId],
        () =>
            callApi({
                method: 'GET',
                url: `/api/Credentials/checkAssigmentStatus/${id}/${targetPersonId}`,
            }).then((data) => data.data),
        {
            enabled: Boolean(id) && Boolean(targetPersonId),
        },
    )
}

export const useCredentialBusinessRequest = (
    targetPersonId,
    resourceId,
    resourceTypeId,
    resourceAssignmentId,
) => {
    const callApi = useAxios()
    return useQuery(
        [
            CREDENTIAL_KEY_PREFIX,
            'Business_Request',
            targetPersonId,
            resourceId,
            resourceTypeId,
            resourceAssignmentId,
        ],
        () =>
            callApi({
                method: 'GET',
                url: `/api/credentials/businessRequestItemAssignmentDetails?targetPersonId=${targetPersonId}&resourceId=${resourceId}&resourceTypeId=${resourceTypeId}&resourceAssignmentId=${resourceAssignmentId}`,
            }).then((data) => data.data),
        {
            enabled:
                Boolean(targetPersonId) &&
                Boolean(resourceId) &&
                Boolean(resourceTypeId) &&
                Boolean(resourceAssignmentId),
        },
    )
}

export const useCredentialOwners = (id) => {
    const callApi = useAxios()
    return useQuery(
        [`${CREDENTIAL_KEY_PREFIX}_OWNERS`, id],
        () =>
            callApi({
                method: 'GET',
                url: `/api/People/resourceOwners?resourceId=${id}`,
            }).then((data) => data.data),
        {
            enabled: Boolean(id),
        },
    )
}
