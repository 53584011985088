import {
    directAssignedOnlyFilterColors,
    timeConstrainedOnlyFilterColors,
} from './colors'

const filters = [
    {
        forRequestAccess: true,
        forManageAccess: true,
        name: 'ShoppingFor',
        requireAccess: {
            control: 'ITShop-ShopForTargetPerson-Control',
        },
        order: 1,
    },
    {
        forManageAccess: true,
        dynamic: true,
        name: 'ShowOnlyDirectAssigned',
        title: 'Common_ShowDirectAssignedOnly',
        requireAccess: {
            control: 'ITShop-PreApprovedApplicationRoles-Control',
        },
        order: 2,
        colorCode: directAssignedOnlyFilterColors,
        label: 'Common_ShowDirectAssignedOnly',
        type: 'checkbox',
        contextProp: 'showDirectAssignedOnly',
        trueValueIndicator: 'Common_ShowDirectAssignedOnly',
        queryParam: {
            name: 'showDirectAssignedOnly',
        },
    },
    {
        forManageAccess: true,
        dynamic: true,
        name: 'ShowOnlyTimeConstrained',
        title: 'ManageAccess_ShowTimeConstrained',
        requireAccess: {
            control: 'ITShop-PreApprovedApplicationRoles-Control',
        },
        order: 2,
        colorCode: timeConstrainedOnlyFilterColors,
        label: 'ManageAccess_ShowTimeConstrained',
        type: 'checkbox',
        contextProp: 'showTimeConstrained',
        trueValueIndicator: 'ManageAccess_ShowTimeConstrained',
        queryParam: {
            name: 'showTimeConstrained',
        },
    },
    {
        forRequestAccess: true,
        dynamic: true,
        name: 'MailboxType',
        title: 'Common_MailboxType',
        requireAccess: { control: 'ITShop-MailboxesMailboxType-Control' },
        order: 2,
        icon: {
            name: 'Mailbox',
            color: '#959598',
        },
        colorCode: {
            primary: '#307fc1',
            secondary: 'rgba(48, 127, 193, 0.09)',
        },
        type: 'autocomplete',
        label: 'Common_MailboxType',
        placeholder: 'Common_SelectMailboxType',
        optionConfig: {
            type: 'label',
            labelProp: 'friendlyName',
        },
        url: '/api/MailBoxes/mailBoxTypes',
        queryParams: {
            take: 25,
        },
        debounceInterval: 500,
        dedupingInterval: 1800000,
        contextProp: 'mailboxTypeId',
        queryParam: {
            name: 'typeId',
            valueProp: 'id',
        },
    },

    {
        forRequestAccess: true,
        dynamic: true,
        name: 'AdvancedSearch',
        title: 'Common_AdvancedSearch',
        requireAccess: {
            control: 'ITShop-MailboxesAdvancedSearch-Control',
        },
        order: 3,
        icon: {
            name: 'AdvancedSearch',
            color: '#959598',
        },
        type: 'advancedSearch',
        forms: {
            title: 'Common_Forms',
            colorCode: {
                primary: '#2920ad',
                secondary: '#ebeaf7',
            },
            filters: [
                {
                    name: 'FriendlyName',
                    type: 'text',
                    placeholder: 'Common_DisplayName',
                    contextProp: 'advancedSearch.forms.friendlyName',
                    queryParam: {
                        name: 'friendlyName',
                        type: 'string',
                    },
                },
                {
                    name: 'Owner',
                    type: 'autocomplete',
                    label: 'Mailboxes_ByOwner',
                    placeholder: 'Common_SelectPerson',
                    optionConfig: {
                        type: 'person',
                        labelProp: 'friendlyName',
                        imageUrlProp: 'imageUrl',
                    },
                    url: '/api/people/search',
                    queryParams: {
                        take: 10,
                    },
                    debounceInterval: 500,
                    dedupingInterval: 60000,
                    contextProp: 'advancedSearch.forms.owner',
                    queryParam: {
                        name: 'OwnerPersonId',
                        valueProp: 'id',
                    },
                },
                {
                    name: 'PrimaryEmailAddress',
                    type: 'text',
                    placeholder: 'Common_Email',
                    contextProp: 'advancedSearch.forms.primaryEmailAddress',
                    queryParam: {
                        name: 'primaryEmailAddress',
                        type: 'string',
                    },
                },
                {
                    name: 'Alias',
                    type: 'text',
                    placeholder: 'Common_Alias',
                    contextProp: 'advancedSearch.forms.alias',
                    queryParam: {
                        name: 'alias',
                        type: 'string',
                    },
                },
            ],
        },
        tags: {
            title: 'Common_Tags',
            colorCode: {
                primary: '#f81983',
                secondary: '#fee8f2',
            },
            contextProp: 'advancedSearch.tags',
        },
    },
]

const attributes = [
    {
        name: 'friendlyName',
        label: 'Common_FriendlyName',
        isHeader: true,
        sortable: true,
        style: { fontWeight: 'bold', color: '#282828' },
        moreInfoIcon: { hideInManageAccess: true },
    },

    {
        name: 'name',
        label: 'Common_TechnicalName',
        sortable: true,
    },
    {
        name: 'alias',
        label: 'Common_Alias',
        sortable: true,
    },
    {
        name: 'primaryEmailAddress',
        label: 'Common_Email',
        sortable: true,
    },
    {
        name: 'resourceSystemFriendlyName',
        label: 'Common_ResourceSystem',
        sortable: true,
    },

    {
        name: 'mailboxTypeName',
        label: 'Common_MailboxType',
        sortable: true,
    },
    {
        name: 'accessLevel',
        manageAccessOnlyAttribute: true,
        label: 'Common_AccessLevel',
    },
    {
        name: 'assignee',
        label: 'Common_AssignedVia',
        manageAccessOnlyAttribute: true,
    },
    {
        label: 'Common_EndDate',
        sortable: false,
        manageAccessOnlyAttribute: true,
        component: { name: 'EndDateManageAccess' },
    },
]

const mailboxesConfig = {
    name: 'Mailboxes',
    resourceTypeName: 'MailBox',
    title: 'Common_Mailboxes',
    route: '/mailboxes',
    requireAccess: {
        page: 'ITShop-MailBoxes-Page',
    },
    order: 7,
    icon: {
        small: 'MailboxesSmall',
        large: 'MailboxesLarge',
    },
    dataSource: {
        type: 'MS_DS',
        url: '/api/mailboxes',
    },
    showTimeConstrainedFilter: true,
    showDirectAssignedOnlyFilter: true,
    workflows: [],
    filters: filters,

    attributes: attributes,
}

export default mailboxesConfig
