import { FC, useState } from 'react'
import { Box, styled } from '@material-ui/core'
import { Modal } from 'components/Modal'
import { PasswordInput } from 'components/PasswordInput'
import { ItemDetails } from 'components'
import { useTranslation } from 'react-i18next'

const TextButton = styled(Box)({
    color: '#307fc1',
    fontSize: '14px',
    display: 'flex',
    alignItems: 'center',
    paddingLeft: '56px',
    '&:hover': {
        cursor: 'pointer',
    },
})

export interface EnterPasswordProps {
    open?: any
    onClose?: any
    onReset?: any
    onCancel?: any
    onSucces?: any
}

export const EnterPassword: FC<EnterPasswordProps> = (props) => {
    const { open, onClose, onSucces, onReset, onCancel } = props
    const [value, setValue] = useState('default')
    const { t } = useTranslation()

    const footerContent = (
        <Box display="flex" width="100%" padding="24px 32px">
            <Box minWidth="88px">
                <ItemDetails.ActionButton
                    fontColor="#ffffff"
                    bgColor={'#307fc1'}
                    width="100%"
                    onClick={onSucces}
                >
                    {t('Common_Ok')}
                </ItemDetails.ActionButton>
            </Box>

            <Box minWidth="130px" pl="30px">
                <ItemDetails.ActionButton
                    fontColor="#ffffff"
                    bgColor={'#aab0b4'}
                    width="100%"
                    onClick={onCancel}
                >
                    {t('Common_Cancel')}
                </ItemDetails.ActionButton>
            </Box>

            <TextButton onClick={onReset}>
                {t('Common_ResetPassword')}
            </TextButton>
        </Box>
    )

    return (
        <Modal
            open={open}
            onClose={onClose}
            title={t('Common_EnterYourMasterPasswordToContinue')}
            footerContent={footerContent}
        >
            <Box>
                <PasswordInput value={value} />
            </Box>
        </Modal>
    )
}
